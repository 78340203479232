import { BaseResponse } from "../models/base-response";
import modifiedFetch from "../../../setup/axios/ModifiedFetch";
import { GetInTouchModel } from "../models/getInTouch/get-in-touch-model";
import { SendEmailNotifications } from "../models/getInTouch/send-email-notification";
const API_URL = process.env.REACT_APP_NOTIFICATION_BASE_URL;
const API_KEY = process.env.REACT_APP_X_API_KEY;


export async function sendNotification(request: SendEmailNotifications<GetInTouchModel>): Promise<BaseResponse<any>> {
    return await modifiedFetch({
        url: `${API_URL}`,
        method: 'POST',
        data: request,
        xApiKey: API_KEY,
    });
}