import { FC, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import toast from "react-hot-toast";
import Modal from "../../components/shared/overlays/Modal";
import Dropdown from "../../components/shared/forms/Dropdown";
import TextArea from "../../components/shared/forms/TextArea";

import { scrollToTop } from "../../helpers/ScrollHelper";
import { GetInTouchModel } from "../../../core/models/getInTouch/get-in-touch-model";
import { SendEmailNotifications } from "../../../core/models/getInTouch/send-email-notification";
import { sendNotification } from "../../../core/services/notification-service";
import { shallowEqual, useSelector } from "react-redux";
import { RootState } from "../../../../setup";
import { PersonalDataModel } from "../../../core/models/personal-data-model";

type Props = {
  show: any;
  onClose: any;
  queryRelatedOptions: Array<any>;
};

const GetInTouch: FC<Props> = ({ show, onClose, queryRelatedOptions }) => {
  const adiData: PersonalDataModel = useSelector<RootState>(
    ({ auth }) => auth.personalData,
    shallowEqual
  ) as PersonalDataModel;
  const validationSchema = Yup.object().shape({
    query_related_option_value: Yup.number().required("This field is required"),
    description: Yup.string()
      .required("This field is required")
      .min(50, "Query must be at least 50 characters."),
  });
  const [showDescription, setShowDescription] = useState<boolean>(false);
  const [isConfirmed, setIsConfirmed] = useState<boolean>(false);
  const formik = useFormik({
    initialValues: {
      query_related_option_label: "",
      query_related_option_value: "",
      description: "",
    },
    enableReinitialize: true,
    validateOnChange: true,
    validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      let getInTouchEmail: SendEmailNotifications<GetInTouchModel> = {
        action_name: "adi-get-in-touch",
        email: `${process.env.REACT_APP_ADIN_SUPPORT_EMAIL}`,
        email_data: {
          query_content: values.description == "" ? "-" : values.description,
          query_subject: values.query_related_option_label,
          booking_email: adiData.email,
          ADI_BADGE: adiData.adi_number,
          FULLNAME: adiData.full_name,
        },
      };
      let res = await sendNotification(getInTouchEmail).catch((error) => {
        setSubmitting(false);
        if (!error?.errorMessage) {
          toast.error("Notification not submitted");
        }
      });
      if (res) {
        handleClose();
        setSubmitting(false);
        scrollToTop();
      }
    },
  });

  const resetComponent = () => {
    setShowDescription(false);
    formik.resetForm();
  };

  const handleClose = () => {
    resetComponent();

    onClose(null);
  };

  return (
    <>
      <Modal
        title="Get in touch"
        open={show}
        onClose={() => {
          isConfirmed ? setIsConfirmed(false) : handleClose();
        }}
        onSubmit={() => {
          if (isConfirmed) {
            formik.handleSubmit();
          } else if (formik.isValid) {
            setIsConfirmed(true);
          }
        }}
        submitText={isConfirmed ? "Confirm" : "Submit"}
        closeText={isConfirmed ? "Go back" : "Cancel"}
        disableSubmit={formik.isSubmitting}
        disabled={formik.isSubmitting}
      >
        {!isConfirmed ? (
          <>
            <Dropdown
              label={"What does your query relate to?"}
              name="query_related_option_value"
              id="query_related_option_value"
              options={queryRelatedOptions}
              disabled={formik.isSubmitting}
              onChange={(e) => {
                formik.setFieldValue("query_related_option_value", e?.value);
                formik.setFieldValue("query_related_option_label", e?.label);
                setShowDescription(true);
              }}
              value={{
                value: formik.values.query_related_option_value,
                label: formik.values.query_related_option_label,
              }}
              placeholder={"Please choose an option"}
              error={
                formik.touched.query_related_option_value &&
                formik.errors.query_related_option_value
                  ? true
                  : false
              }
              errorMsg={formik.errors.query_related_option_value}
              required={true}
            />
            {showDescription && (
              <TextArea
                {...formik.getFieldProps("description")}
                className="form-control"
                name="description"
                id="description"
                onChange={(v) => {
                  formik.setFieldValue("description", v.target.value);
                }}
                label={"What can we help you with?"}
                placeholder={"Please provide as much detail as possible"}
                disabled={formik.isSubmitting}
                rows={15}
                required={true}
                error={
                  formik.touched.description && formik.errors.description
                    ? true
                    : false
                }
                errorMsg={formik.errors.description}
              ></TextArea>
            )}
          </>
        ) : (
          <>
            <p className="text-base font-bold mb-3 ">
              You are about to submit this query:
            </p>
            <div className="border border-gray-600 px-2 py-2 bg-gray-300 overflow-y-auto mb-2">
              <p className="text-base mb-3 ">{formik.values.description}</p>
            </div>
            <p className="text-base font-bold mb-3 ">
              We aim to respond to all queries within one working day. Please
              keep an eye on your emails.
            </p>
          </>
        )}
      </Modal>
    </>
  );
};

export { GetInTouch };
