import * as auth from "../redux/AuthRedux";

export const setMobileLoginData = async (dispatch: any) => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  let sessionFromURL = Object.fromEntries(urlSearchParams.entries());
  let url = window.location.href;
  dispatch(auth.actions.setIsMobile(true));
  dispatch(auth.actions.setIsLogin(true));
  if (url.includes("mobileVersion")) {
    dispatch(auth.actions.setMobileVersion(sessionFromURL.mobileVersion));
  }
};
