import React, { useEffect, useState } from "react";
import ReactGA from "react-ga4";
import {
  ChatBubbleOvalLeftEllipsisIcon,
  PhoneIcon,
  InformationCircleIcon,
} from "@heroicons/react/24/outline";
import { toAbsoluteUrl } from "../../helpers";
import Button from "../../components/shared/elements/Button";

import { GetInTouch } from "./GetInTouch";
import toast from "react-hot-toast";
import { getQueryRelatedOptions } from "../../../core/services/adi-service";
interface IProps {
  isNotAllowed: boolean;
}

const ContactInfo: React.FC<IProps> = (props) => {
  const title = props.isNotAllowed
    ? "You are not allowed to access the ADI portal,"
    : "Have a question, suggestions or need to speak to us?";

  ReactGA.event("open_help_tab");
  const [showGetInTouchModal, setShowGetInTouchModal] =
    useState<boolean>(false);
  const onClose = () => {
    setShowGetInTouchModal(false);
  };
  const [queryRelatedOptions, setQueryRelatedOptions] = useState<Array<any>>(
    []
  );

  useEffect(() => {
    async function fetchQueryRelatedOptions() {
      let response = await getQueryRelatedOptions().catch((error) => {
        if (!error?.errorMessage)
          toast.error("Something went wrong ,please try again later");
      });
      if (!response) {
        return;
      }
      let queryRelatedOptionsList = response.map((q: any) => ({
        label: q.name,
        value: q.id,
      }));
      setQueryRelatedOptions(queryRelatedOptionsList);
    }
    fetchQueryRelatedOptions();
  }, []);
  return (
    <div className="">
      <img
        src={toAbsoluteUrl("/assets/media/help/helpCentre-banner.png")}
        alt="help"
        className="mt-5 w-full"
      />
      <div className="bg-white p-5">
        <p className="text-darkBlue text-sm mb-3">
          {title} Please contact us by using the options below.
        </p>

        <ul className="p-0">
          <li className="py-3 border-b">
            <a href="sms:+44 7723 571368" className="flex items-start w-fit">
              <ChatBubbleOvalLeftEllipsisIcon className="w-6 h-6 me-3 text-yellow" />
              <span className="text-darkBlue text-base leading-4">
                SMS
                <br />
                <span className="text-darkBlue text-[10px]">
                  +44 7723 571368
                </span>
              </span>
            </a>
          </li>
          <li className="py-3 border-b">
            <a href="tel:0333 188 6627" className="flex items-start w-fit">
              <PhoneIcon className="w-6 h-6 me-3 text-yellow" />
              <span className="text-darkBlue text-base leading-4">
                Phone
                <br />
                <span className="text-darkBlue text-[10px]">0333 188 6627</span>
                <span></span>
              </span>
            </a>
          </li>
          <li className="py-3 border-b">
            <Button
              type="button"
              className="p-1"
              size="large"
              fullWidth
              colour="darkBlue"
              onClick={() => {
                setShowGetInTouchModal(true);
              }}
            >
              Get in touch
            </Button>
          </li>
          <li className="py-3">
            <div className="flex items-center w-fit">
              <InformationCircleIcon className="w-6 h-6 flex-shrink-0 me-3 text-darkBlue" />
              <span className="text-darkBlue text-sm leading-5">
                Our ADI support lines are open on weekdays from 8:30am to 6pm
              </span>
            </div>
          </li>
        </ul>
      </div>
      <GetInTouch
        onClose={onClose}
        show={showGetInTouchModal}
        queryRelatedOptions={queryRelatedOptions}
      />
    </div>
  );
};

export default ContactInfo;
