import { FC, useEffect, useState } from "react";
import {
  checkIsQuestionnareCompleted,
  getHasPenddingDocument,
  getPersonalData,
  getSavedSteps,
} from "../../../../core/services/adi-service";
import { PersonalDataModel } from "../../../../core/models/personal-data-model";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import * as auth from "../../../pages/auth/redux/AuthRedux";
import Alert from "../../shared/overlays/Alert";
import Button from "../../shared/elements/Button";
import { CompleteQuestionnaireDataModal } from "../../account-settings/general-questionnaire-data/CompleteQuestionnaireDataModal";

const DashboardAlerts: FC<any> = ({ showPrismicNotification }) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [hasPenddingDocument, setHasPenddingDocument] =
    useState<boolean>(false);
  const [hasPendingTerms, setHasPendingTerms] = useState<boolean>(false);
  const [hasRejectedTerms, setHasRejectedTerms] = useState<boolean>(false);
  const [personalData, setPersonalData] = useState<PersonalDataModel>();
  const [hasUnRatedCars, setHasUnRatedCars] = useState<boolean>(false);
  const [showQuestionnaire, setShowQuestionnaire] = useState<boolean>(false);
  const [isCompletedQuestionnaire, setIsCompletedQuestionnaire] =
    useState<boolean>(false);
  const [isLoadingCompletedQuestionnaire, setIsLoadingCompletedQuestionnaire] =
    useState<boolean>(false);
  const dispatch = useDispatch();
  const history = useHistory();
  useEffect(() => {
    checkUnsavedCount();
  }, []);

  useEffect(() => {
    if (
      hasPendingTerms == false &&
      hasRejectedTerms == false &&
      hasPenddingDocument == false &&
      isCompletedQuestionnaire == true &&
      personalData?.has_expired_badge === 0 &&
      personalData?.has_expired_insurance === 0
    ) {
      showPrismicNotification();
    }
  }, [
    hasPendingTerms,
    hasRejectedTerms,
    hasPenddingDocument,
    isCompletedQuestionnaire,
    personalData,
  ]);

  function checkHasUpcomigExpiredDocument() {
    getPersonalData().then((data) => {
      setPersonalData(data.data.results);
    });
  }

  async function checkCompletedQuestionnaire() {
    setIsLoadingCompletedQuestionnaire(true);
    const res = await checkIsQuestionnareCompleted();
    setIsCompletedQuestionnaire(res?.results?.finished_questionnaire);
    setIsLoadingCompletedQuestionnaire(false);
  }

  function checkUnsavedCount() {
    getSavedSteps()
      .then((res) => {
        dispatch(
          auth.actions.setHasUnVerifiedData(
            res.data.results.has_un_verified_imported_data
          )
        );
        if (res.data.results.has_un_verified_imported_data) {
          history.push(`/additional-details-adis${history.location.search}`);
        } else {
          checkCompletedQuestionnaire();
          checkHasPenddingDocument();
          checkHasUpcomigExpiredDocument();
          setLoading(false);
        }
        setHasUnRatedCars(res.data.results.has_cars_with_no_rate);
      })
      .catch((error) => {
        setLoading(false);
      });
  }
  function checkHasPenddingDocument() {
    getHasPenddingDocument()
      .then((res) => {
        if (res.data.results) {
          setHasPenddingDocument(res.data.results.has_pendding_badge);
          setHasPendingTerms(res.data.results.has_pending_terms);
          setHasRejectedTerms(res.data.results.has_rejected_terms);
        }
      })
      .catch((error) => {});
  }
  return (
    <>
      {!loading ? (
        <>
          {!isCompletedQuestionnaire && !isLoadingCompletedQuestionnaire ? (
            <>
              <Alert
                title="Please take a moment to complete the remaining parts of your questionnaire so we can show jobs that meet your preferences"
                colour="red"
                size="sm"
                imgSrc="gif/doc-icon.gif"
                hasClose={true}
                onClick={() => {
                  setShowQuestionnaire(true);
                }}
              />

              <CompleteQuestionnaireDataModal
                show={showQuestionnaire}
                onClose={() => {
                  setShowQuestionnaire(false);
                  checkCompletedQuestionnaire();
                }}
              />
            </>
          ) : (
            <></>
          )}

          {personalData?.has_expired_badge == 1 && (
            <Alert
              customDesc={
                <p className="text-xs sm:text-sm">
                  <strong>
                    {"Your ADI Badge " +
                      (personalData?.badge_remaining_days > 0
                        ? "is about to expire in " +
                          personalData?.badge_remaining_days +
                          " days"
                        : "has expired")}
                  </strong>
                  {
                    ". Please upload your new ADI badge to continue seeing new jobs."
                  }
                </p>
              }
              colour="red"
              imgSrc="gif/doc-icon.gif"
              hasClose={true}
              onClick={() => history.push("/ADI/profile/documents")}
            />
          )}
          {personalData?.has_expired_insurance == 1 && (
            <Alert
              customDesc={
                <p className="text-xs sm:text-sm">
                  <strong>
                    {"Your insurance " +
                      (personalData?.insurance_remaining_days > 0
                        ? "is about to expire in " +
                          personalData?.insurance_remaining_days +
                          " days"
                        : "has expired")}
                  </strong>
                  {
                    ". Please update your insurance details to continue seeing new jobs."
                  }
                </p>
              }
              colour="red"
              imgSrc="gif/doc-icon.gif"
              hasClose={true}
              onClick={() => history.push("/ADI/profile/documents")}
            />
          )}

          {hasPenddingDocument == true ? (
            <Alert
              customDesc={
                <p className="text-xs sm:text-sm">
                  <strong>Your badge is awaiting approval.</strong> It may take
                  a day or two. Please check in later!
                </p>
              }
              colour="gray"
              imgSrc="gif/doc-icon.gif"
              hasClose={true}
            />
          ) : hasPendingTerms == true ? (
            <Alert
              customDesc={
                <p className="text-xs sm:text-sm">
                  <strong>
                    Your terms and conditions are awaiting approval.
                  </strong>{" "}
                  It may take a day or two. Please check in later!
                </p>
              }
              colour="gray"
              imgSrc="gif/doc-icon.gif"
              hasClose={true}
            />
          ) : (
            hasRejectedTerms == true && (
              <Alert
                customDesc={
                  <p className="text-xs sm:text-sm">
                    <span className="w-full">
                      Please update your terms and conditions.
                      <span className="w-fit inline-block">
                        {" "}
                        <Button
                          onClick={() =>
                            history.push(
                              "/ADI/profile/rates-cancellation-policy"
                            )
                          }
                          colour="link"
                          size="fit"
                        >
                          <strong className="text-xs sm:text-sm ms-1">
                            Go to Rates
                          </strong>
                        </Button>
                      </span>
                    </span>
                  </p>
                }
                colour="gray"
                imgSrc="gif/doc-icon.gif"
                hasClose={true}
              />
            )
          )}
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export { DashboardAlerts };
