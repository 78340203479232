import Amplify, { Hub, Auth } from "aws-amplify";

import { federatedSignIn } from "../../app/content/pages/auth/redux/AuthCRUD";
import {
  CognitoUser,
  CognitoUserSession,
  CognitoIdToken,
  CognitoRefreshToken,
  CognitoAccessToken,
  CognitoUserPool,
} from "amazon-cognito-identity-js";
import { setMobileLoginData } from "../../app/content/pages/auth/components/MobileLogin";
import store from "../../setup/redux/Store";

const amplifyConfigurations = {
  Auth: {
    identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
    region: process.env.REACT_APP_REGION,
    identityPoolRegion: process.env.REACT_IDENTITY_POOL_REGION,
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,
    oauth: {
      domain: process.env.REACT_APP_OAUTH_DOMAIN,
      redirectSignIn: process.env.REACT_APP_OAUTH_REDIRECT_SIGNIN,
      redirectSignOut: process.env.REACT_APP_OAUTH_REDIRECT_SIGNOUT,
      responseType: process.env.REACT_APP_OAUTH_RESPONSE_TYPE,
    },
  },
};

const urlSearchParams = new URLSearchParams(window.location.search);
let sessionFromURL = Object.fromEntries(urlSearchParams.entries());

const listener = (data: any) => {
  const userExistsMsg = "Already+found+an+entry+for+username+";
  if (data.payload.event === "signIn_failure") {
    if (data.payload.data.message.includes(userExistsMsg + "facebook")) {
      federatedSignIn("Facebook");
    } else if (data.payload.data.message.includes(userExistsMsg + "google")) {
      federatedSignIn("Google");
    } else if (
      data.payload.data.message.includes(userExistsMsg + "signinwithapple")
    ) {
      federatedSignIn("SignInWithApple");
    } else {
      // window.location.href = '../';
    }
  }
};

const initializeAmplify = () => {
  let url = window.location.href;
  if (url.includes("code=") && url.includes("/ADI/settings/integration")) {
    window.location.href = url.replace("code=", "x=");
  }
  Amplify.configure(amplifyConfigurations);
  if (
    url.includes("idToken") &&
    url.includes("refreshToken") &&
    url.includes("accessToken") &&
    url.includes("username")
  ) {
    localStorage.clear();
    const localSession = new CognitoUserSession({
      IdToken: new CognitoIdToken({ IdToken: sessionFromURL.idToken }),
      RefreshToken: new CognitoRefreshToken({
        RefreshToken: sessionFromURL.refreshToken,
      }),
      AccessToken: new CognitoAccessToken({
        AccessToken: sessionFromURL.accessToken,
      }),
    });

    const localUser = new CognitoUser({
      Username: sessionFromURL.username,
      Pool: new CognitoUserPool({
        UserPoolId: process.env.REACT_APP_USER_POOL_ID ?? "",
        ClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID ?? "",
      }),
    });
    localUser.setSignInUserSession(localSession);
    Auth.currentAuthenticatedUser = async () => localSession;
    setMobileLoginData(store.dispatch);
  }
  Hub.listen("auth", listener);
};

export default initializeAmplify;
